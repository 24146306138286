import React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import Router from './Router';
import ShopMenu from './Components/Menus/ShopMenu';
import { ProductRoutePrefixProvider, useProductRoutePrefix } from './ProductRoutePrefixContext';
const App: React.FC = () => {
    return (
        <ProductRoutePrefixProvider>
            <AppContent />
        </ProductRoutePrefixProvider>
    );
};

const AppContent: React.FC = () => {
    const prefix = useProductRoutePrefix();
    return (
        <>
            <ShopMenu />
            <Link
                to={`${prefix}/shop`}
                className={'navbar-item is-tab'}
                data-deploy='#menu-shop-content'
            >
                Boutique
            </Link>
            <Routes>
                <Route
                    path={`${prefix}/*`}
                    element={<Router />}
                />
            </Routes>
        </>
    );
};

export default App;
